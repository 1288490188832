import LoginPage from "@/components/LoginPage";
import OrdersPage from "@/components/OrdersPage";
import ManageOrder from "@/components/ManageOrder";
import { createRouter, createWebHashHistory } from "vue-router";
import { auth } from "./config/db";

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/:pathMatch(.*)*",
      redirect: "/login",
    },
    {
      path: "/",
      redirect: "/login",
    },
    {
      path: "/login",
      name: "Login",
      component: LoginPage,
    },
    {
      path: "/orders",
      name: "Orders",
      component: OrdersPage,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/orders/:collection/:order",
      name: "ManageOrder",
      component: ManageOrder,
      meta: {
        requiresAuth: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const currentUser = auth.currentUser;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && !currentUser) next("login");
  else if (!requiresAuth && currentUser) next("orders");
  else next();
});

export default router;
