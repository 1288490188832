<template>
  <div
    class="auth-layout-wrap"
    style="background-image: url(/assets/images/background-jp.jpg)"
  >
    <div class="auth-content">
      <div class="card card-profile-1 o-hidden">
        <div class="row">
          <div class="col-md-6">
            <div class="p-4">
              <div class="auth-logo text-center mb-4">
                <img alt="" src="/assets/images/logo.jpg" />
              </div>
              <h1 class="mb-3 text-18">JP Kundenportal</h1>
              <p v-if="!checkEmail">
                Bitte gebe die E-Mail-Adresse an, welche du für deine Bestellung
                verwendet hast. Wir senden dir einen Link, mit welchem du deine
                Bestellungen bearbeiten kannst.
              </p>
              <p v-else>
                Du hast den Link auf einem anderen Gerät geöffnet. Bitte
                bestätige zur Sicherheit deine E-Mail-Adresse!
              </p>
              <p v-if="invalidEmailInput" style="color: red">
                Deine angegebene E-Mail-Adresse wurde nicht angenommen. Bitte
                überprüfe sie.
              </p>
              <p v-if="invalidCheckingEmail" style="color: red">
                Deine angegebene E-Mail-Adresse entspricht nicht der, an welche
                der Link geschickt wurde! Bitte versuche es erneut.
              </p>
              <form v-on:submit.prevent="login">
                <div class="form-group">
                  <label for="email">E-Mail</label>
                  <input
                    id="email"
                    v-model="email"
                    class="form-control form-control-rounded"
                    type="text"
                  />
                </div>
                <button
                  v-if="!checkEmail && !loading"
                  class="btn btn-rounded btn-primary btn-block mt-2"
                  type="button"
                  @click="login"
                  v-on:keyup.enter="checkLogin"
                >
                  Login
                </button>
                <button
                  v-else-if="!loading"
                  class="btn btn-rounded btn-primary btn-block mt-2"
                  type="button"
                  @click="checkLogin"
                  v-on:keyup.enter="checkLogin"
                >
                  Bestätigen
                </button>
                <button
                  v-else
                  class="btn btn-rounded btn-primary btn-block mt-2"
                  type="button"
                  @click="checkLogin"
                  v-on:keyup.enter="checkLogin"
                >
                  Lädt...
                </button>
              </form>
            </div>
          </div>
          <div
            class="col-md-6 text-center"
            style="
              background-size: cover;
              background-image: url(/assets/images/car_motor_compartment.jpg);
            "
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */

import Swal from "sweetalert2";
import {
  sendSignInLinkToEmail,
  signInWithEmailLink,
  isSignInWithEmailLink,
} from "firebase/auth";
import { auth } from "../config/db";

export default {
  name: "LoginPage",

  data() {
    return {
      email: "",
      checkEmail: false,
      invalidEmailInput: false,
      invalidCheckingEmail: false,
      firstCheck: true,
      loading: false,
    };
  },

  created: function () {
    this.checkLogin();
  },

  methods: {
    login: function () {
      this.loading = true;

      const actionCodeSettings = {
        // URL must be whitelisted in the Firebase Console.
        url: "https://portal.jp-superstore.de/",
        handleCodeInApp: true,
      };

      // Send authentication email
      sendSignInLinkToEmail(auth, this.email, actionCodeSettings)
        .then(() => {
          this.invalidEmailInput = false;
          window.localStorage.setItem("loginEmail", this.email);
          Swal.fire({
            icon: "success",
            title: "E-Mail wurde gesendet!",
            text: "Wir haben dir zur Authentifizierung eine E-Mail gesendet. Klicke den Link in der E-Mail um deine Bestellungen einzusehen und bearbeiten zu können!",
            showConfirmButton: false,
          });
          this.loading = false;
        })
        .catch((error) => {
          this.invalidEmailInput = true;
          console.error(error);
          this.loading = false;
        });
    },

    checkLogin: function () {
      // If current link is an email login link
      if (isSignInWithEmailLink(auth, window.location.href)) {
        this.loading = true;

        let email = window.localStorage.getItem("loginEmail") || this.email;
        if (!email) {
          this.checkEmail = true;
          this.loading = false;
        } else {
          signInWithEmailLink(auth, email, window.location.href)
            .then((result) => {
              this.invalidCheckingEmail = false;
              window.localStorage.removeItem("loginEmail");
              console.log(result);
              window.location.href = "/";
              this.loading = false;
            })
            .catch((error) => {
              if (this.firstCheck) {
                Swal.fire({
                  icon: "error",
                  title: "Anmeldung fehlgeschlagen.",
                  text: "Die Anmeldung ist fehlgeschlagen. Überprüfe deine eigegebenen Daten und versuche es erneut.",
                  showConfirmButton: false,
                });
              }
              this.firstCheck = false;
              this.invalidCheckingEmail = true;
              console.error(error);
              this.loading = false;
            });
        }
      }
    },
  },
};
</script>
