<template>
  <div class="app-admin-wrap">
    <div class="main-header">
      <div class="logo">
        <img alt="" src="/assets/images/logo.jpg" />
      </div>

      <div class="d-flex align-items-center"></div>

      <div style="margin: auto"></div>

      <div class="header-part-right">
        <div class="dropdown">
          <div class="user colalign-self-end">
            <img
              id="userDropdown"
              alt=""
              aria-expanded="false"
              aria-haspopup="true"
              data-toggle="dropdown"
              src="/assets/images/logo.jpg"
              style="cursor: pointer"
            />
            <div
              aria-labelledby="userDropdown"
              class="dropdown-menu dropdown-menu-right"
            >
              <a class="dropdown-item" href="#" @click="logout">Abmelden</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="side-content-wrap">
      <div class="sidebar-left open d-none d-sm-block">
        <ul class="navigation-left">
          <li class="nav-item active">
            <router-link :to="{ name: 'Orders' }" class="nav-item-hold">
              <i class="nav-icon i-Library" />
              <span class="nav-text">Bestellungen</span>
            </router-link>
            <div class="triangle"></div>
          </li>
        </ul>
      </div>
      <div class="sidebar-overlay"></div>
    </div>
    <!--=============== Left side End ================-->

    <!-- ============ Body content start ============= -->
    <div class="main-content-wrap sidenav-open d-flex flex-column">
      <div class="breadcrumb">
        <h1>Deine Bestellungen</h1>
      </div>
      <div class="separator-breadcrumb border-top"></div>

      <div class="row">
        <div class="col-md-12">
          <div class="card card-profile-1 mb-4">
            <div class="card-body">
              <h3>Willkommen, {{ email }}!</h3>
              <h5>Anzahl deiner Bestellungen: {{ orders.length }}</h5>
            </div>
          </div>
        </div>
      </div>

      <div v-for="order in orders" :key="order.id">
        <div class="row">
          <div class="col-md-12">
            <router-link
              :to="{
                name: 'ManageOrder',
                params: {
                  order: order.id,
                  collection: order.collection,
                },
              }"
            >
              <div class="card card-profile-1 hover-shadow mb-4">
                <div class="card-body">
                  <div class="row">
                    <div
                      class="col-2"
                      style="
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                      "
                    >
                      <span class="text-default mr-2 h1">
                        <i class="nav-icon i-Eye font-weight-bold" />
                      </span>
                    </div>
                    <div class="col-9" style="text-align: left">
                      <p
                        v-if="order.customer_verified"
                        class="h3"
                        style="color: green"
                      >
                        Bestätigt
                      </p>

                      <p class="h2 font-weight-bold text-body">
                        {{ order.amount }}x {{ order.productName }}
                      </p>
                      <h3>
                        <span v-if="order.company">{{ order.company }}: </span
                        >{{ order.firstname }} {{ order.lastname }}
                      </h3>
                      <h4>
                        {{ order.street }} {{ order.street_number }},
                        {{ order.plz }} {{ order.city }},
                        {{ order.country }}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- ============ Body content End ============= -->
  </div>
  <!--=============== End app-admin-wrap ================-->
</template>

<script>
/* eslint-disable no-console */

import { collection, query, where, getDocs } from "firebase/firestore";
import { auth, firestore } from "../config/db";

export default {
  name: "OrdersPage",

  data() {
    return {
      email: "",
      orders: [],
      collections: [
        "pace-ticket-orders",
        "orders",
        "2020_quartett",
        "2020_2-biografie-orders",
        "2020_3-carlender-orders",
        "2021_1-audiobook-orders",
        "2021_2-carlender-orders",
        "2021_3-biografie-orders",
        "2022_1-boostwars-orders",
        "2022_2-carlender-orders",
        "2023_4-carlender-orders",
      ],
    };
  },

  created: function () {
    this.userData();
  },

  methods: {
    /**
     * Log out current user
     */
    logout: function () {
      auth.signOut().then(() => {
        console.log("Signed out");
        this.$router.replace({ path: "/login" });
      });
    },

    /**
     * Get user data
     */
    userData: function () {
      const user = auth.currentUser;

      if (user != null) {
        this.email = user.email;
        this.getOrders();
      }
    },

    /**
     * Get label by doc name
     */
    getProductName: function (collectionName) {
      const mapping = {
        "pace-ticket-orders": "JP PACE Ticket(s)",
        orders: "Carlender 2019",
        "2020_quartett": "Quartett",
        "2020_2-biografie-orders": "Biografie 2020",
        "2020_3-carlender-orders": "Carlender 2021",
        "2021_1-audiobook-orders": "Biografie Hörbuch",
        "2021_2-carlender-orders": "Carlender 2022",
        "2021_3-biografie-orders": "Biografie 2021",
        "2022_1-boostwars-orders": "Boost Wars GT-R Modellauto",
        "2022_2-carlender-orders": "Carlender 2023",
        "2023_4-carlender-orders": "Carlender 2024",
      };

      return mapping[collectionName];
    },

    /**
     * Get orders of current user
     */
    getOrders: function () {
      this.orders = [];

      this.collections.forEach((collectionName) => {
        const ref = collection(firestore, collectionName);
        const q = query(ref, where("email", "==", this.email));
        getDocs(q).then((querySnapshot) =>
          querySnapshot.forEach((doc) => {
            const docData = doc.data();

            // Show only paid orders
            if (docData.payment_status) {
              docData.id = doc.id;
              docData.productName = this.getProductName(collectionName);
              docData.collection = collectionName;

              if (collectionName === "pace-ticket-orders") {
                const keys = [
                  "amount_adult",
                  "amount_adult_tablet",
                  "amount_reduced",
                  "amount_reduced_tablet",
                ];

                docData.amount = keys
                  .map((key) => parseInt(docData[key]))
                  .filter((amount) => !isNaN(amount))
                  .reduce((a, b) => a + b);
              }

              // Ensure that snapshot updates are handled appropriately
              const existingOrderIndex = this.orders.findIndex(
                (order) => order.id === doc.id,
              );

              if (existingOrderIndex >= 0) {
                this.orders[existingOrderIndex] = docData;
              } else {
                this.orders.push(docData);
              }

              // Sort orders by order date
              this.orders.sort(
                (o1, o2) => new Date(o2.order_date) - new Date(o1.order_date),
              );
            }
          }),
        );
      });
    },
  },
};
</script>
