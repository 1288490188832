import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

let config = {
  apiKey: "AIzaSyANSwulqn1LZIOrnN6bBwh_SdiNxjO5iQU",
  authDomain: "jpsuperstore-714e2.firebaseapp.com",
  databaseURL: "https://jpsuperstore-714e2.firebaseio.com",
  projectId: "jpsuperstore-714e2",
  storageBucket: "jpsuperstore-714e2.appspot.com",
  messagingSenderId: "86722759770",
  appId: "1:86722759770:web:7738490f1e362d17ee1c63",
  measurementId: "G-VYX9989FJ5",
};

let app = initializeApp(config);
export const firebaseApp = app;

export const db = getDatabase(app);
export const auth = getAuth(app);
export const firestorage = getStorage(app);
export const firestore = getFirestore(app);
