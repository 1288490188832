<style>
.swal2-container:not(.swal2-in) {
  pointer-events: all !important;
}
</style>

<template>
  <div class="app-admin-wrap">
    <div class="main-header">
      <div class="logo">
        <img alt="" src="/assets/images/logo.jpg" />
      </div>

      <router-link
        :to="{ name: 'Orders' }"
        class="nav-item-hold d-block d-sm-none"
        style="color: #000"
      >
        <i class="nav-icon i-Back1 h1 ml-1 pl-4" />
      </router-link>

      <div class="d-flex align-items-center"></div>

      <div style="margin: auto"></div>

      <div class="header-part-right">
        <div class="dropdown">
          <div class="user colalign-self-end">
            <img
              id="userDropdown"
              alt=""
              aria-expanded="false"
              aria-haspopup="true"
              data-toggle="dropdown"
              src="/assets/images/logo.jpg"
              style="cursor: pointer"
            />
            <div
              aria-labelledby="userDropdown"
              class="dropdown-menu dropdown-menu-right"
            >
              <a class="dropdown-item" href="#" @click="logout">Abmelden</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="side-content-wrap">
      <div
        class="sidebar-left open d-none d-sm-block"
        data-perfect-scrollbar
        data-suppress-scroll-x="true"
      >
        <ul class="navigation-left">
          <li class="nav-item">
            <router-link :to="{ name: 'Orders' }" class="nav-item-hold">
              <i class="nav-icon i-Library" />
              <span class="nav-text">Bestellungen</span>
            </router-link>
            <div class="triangle"></div>
          </li>
        </ul>
      </div>
      <div class="sidebar-overlay"></div>
    </div>
    <!--=============== Left side End ================-->

    <!-- ============ Body content start ============= -->
    <div class="main-content-wrap sidenav-open d-flex flex-column">
      <div class="breadcrumb">
        <h1>Bestellungsdaten</h1>
      </div>
      <div class="separator-breadcrumb border-top"></div>

      <div class="container">
        <div class="mx-auto mb-4">
          <div class="card card-profile-1 shadow mb-5 bg-white">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <h2 class="font-weight-bold pt-3">
                    Deine Bestellung ist eingegangen.
                  </h2>
                  <h3>
                    Vielen Dank für dein Vertrauen. Hier siehst du immer den
                    aktuellen Status deiner Bestellung.<br />
                    Auf der linken Seite siehst du deinen Lieferstatus und auf
                    der rechten Seite siehst du den Verarbeitungsstatus deiner
                    Bestellung.
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="documentValues && documentValues.orderid && invoiceUrl"
        class="container"
      >
        <div class="mx-auto mb-4">
          <div class="card card-profile-1 shadow mb-5 bg-white">
            <div class="card-body">
              <div aria-label="Third group" class="btn-group-lg" role="group">
                <a :href="invoiceUrl" class="btn btn-dark m-2" target="_self">
                  <i class="i-Download" style="font-weight: bold" />
                  Rechnung herunterladen
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="documentValues['payment_status'] === 'REFUND-REQUESTED'"
        class="container"
      >
        <div class="row" style="justify-content: center">
          <div class="col-md-6 mb-4">
            <div class="card card-profile-1 shadow p-3 mb-5 bg-white">
              <div class="card-body">
                <span class="text-danger" style="font-size: 32px">
                  <i class="i-Wrench" />
                  Deine Stornierung wurde beantragt.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="collection === 'pace-ticket-orders'" class="container">
        <div class="mx-auto mb-4">
          <div class="card card-profile-1 shadow mb-5 bg-white">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <h2 class="font-weight-bold pt-3">Keine Tickets erhalten?</h2>

                  <h3>
                    Falls du deine Tickets nicht per E-Mail erhalten hast,
                    kannst du sie dir hier einfach herunterladen.
                  </h3>

                  <div class="col-md-12 pt-3">
                    <a
                      class="btn btn-primary"
                      :disabled="!paceTicketsUrl"
                      :href="paceTicketsUrl"
                    >
                      Tickets herunterladen
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="
          documentValues &&
          !documentValues.customer_verified &&
          orderInWordpress &&
          orderInWordpress.status === 'invalid-address'
        "
        class="container"
      >
        <div class="mx-auto mb-4">
          <div class="card card-profile-1 shadow mb-5 bg-white">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <h2 class="font-weight-bold pt-3">
                    Diese Bestellung ist noch nicht bestätigt.
                  </h2>
                  <h3>
                    Bitte überprüfe deine Bestellungsdaten und korrigiere
                    diese.<br />
                    Wenn du dir sicher bist, dass die neuen Daten korrekt sind,
                    markiere die Daten als bestätigt.
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-else-if="
          documentValues &&
          documentValues.customer_verified &&
          orderInWordpress &&
          orderInWordpress.status === 'invalid-address'
        "
        class="container"
      >
        <div class="mx-auto mb-4">
          <div class="card card-profile-1 shadow mb-5 bg-white">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <h2 class="font-weight-bold pt-3">
                    Du hast diese Bestellung bestätigt.
                  </h2>
                  <h3>
                    Wir schicken das Paket nun erneut raus. Du kannst die
                    Bestellungsdaten nun nicht mehr bearbeiten.
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="orderInWordpress" class="container">
        <div class="row" style="justify-content: center">
          <div class="col-md-6 mb-4">
            <div class="card card-profile-1 shadow p-3 mb-5 bg-white">
              <div class="card-body">
                <span class="text-info" style="font-size: 32px">
                  <i class="i-Tag" />

                  <span
                    v-if="
                      orderInWordpress.batch &&
                      orderInWordpress.batch.ausgeliefert
                    "
                  >
                    {{ orderInWordpress.batch.ausgeliefert }}
                  </span>
                  <span v-else> Nicht ausgeliefert </span>
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-4">
            <div class="card card-profile-1 shadow p-3 mb-5 bg-white">
              <div class="card-body">
                <span
                  v-if="orderInWordpress.status_label"
                  class="text-info"
                  style="font-size: 32px"
                >
                  <i class="i-Optimization" />
                  {{ orderInWordpress.status_label }}

                  <div style="font-size: 14px">
                    {{ orderInWordpress.status_excerpt }}
                  </div>
                </span>

                <span v-else class="text-info" style="font-size: 32px">
                  <i class="i-Optimization" />
                  Noch nicht bearbeitet
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row mb-4">
          <div class="col-xl-6 mb-4">
            <div
              class="card card-profile-1 shadow p-3 mb-5 bg-white"
              style="height: 100%"
            >
              <div class="card-body">
                <div class="card-title">
                  <h2>Persönliche Daten</h2>
                </div>

                <div class="text-left" style="margin: auto">
                  <div class="py-1">
                    <h4 title="firstname lastname">
                      <i class="i-Male mr-3" />
                      <strong>Name:</strong>
                      {{ documentValues["firstname"] || "–" }}
                      {{ documentValues["lastname"] || "–" }}
                    </h4>
                  </div>

                  <div class="py-1">
                    <h4 title="street, plz city">
                      <i class="i-Home1 mr-3" />
                      <strong>Anschrift:</strong>
                      {{ documentValues["street"] || "–" }}
                      {{ documentValues["street_number"] || "–" }},
                      {{ documentValues["plz"] || "–" }}
                      {{ documentValues["city"] || "–" }}
                    </h4>
                  </div>

                  <div class="py-1">
                    <h4 title="country">
                      <i class="i-Flag-2 mr-3" />
                      <strong>Land:</strong>
                      {{ documentValues["country"] || "–" }}
                    </h4>
                  </div>

                  <div class="py-1">
                    <h4 title="company">
                      <i class="i-Office-Lamp mr-3" />
                      <strong>Firma:</strong>
                      {{ documentValues["company"] || "–" }}
                    </h4>
                  </div>

                  <div class="py-1">
                    <h4 title="email">
                      <i class="i-Mail mr-3" />
                      <strong>E-Mail:</strong>

                      <a :href="`mailto:${documentValues['email']}`">
                        {{ documentValues["email"] || "–" }}
                      </a>
                    </h4>
                  </div>

                  <div class="py-1">
                    <h4 title="phone">
                      <i class="i-Telephone mr-3" />
                      <strong>Telefonnummer:</strong>

                      <a :href="`tel:${documentValues['phone']}`">
                        {{ documentValues["phone"] || "–" }}
                      </a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 mb-4">
            <div
              class="card card-profile-1 shadow p-3 mb-5 bg-white"
              style="height: 100%"
            >
              <div class="card-body">
                <div class="card-title">
                  <h2>Versanddetails</h2>

                  <p class="text-small text-muted">
                    Bei einer abweichenden Versandadresse
                  </p>
                </div>

                <div class="text-left" style="margin: auto">
                  <div class="py-1">
                    <h4 title="ship_firstname ship_lastname">
                      <i class="i-Male mr-3" />
                      <strong>Name:</strong>

                      {{ documentValues["ship_firstname"] }}
                      {{ documentValues["ship_lastname"] }}

                      <span
                        v-if="
                          !documentValues.ship_firstname &&
                          !documentValues.ship_lastname
                        "
                      >
                        –
                      </span>
                    </h4>
                  </div>

                  <div class="py-1">
                    <h4 title="ship_city ship_street">
                      <i class="i-Home1 mr-3" />
                      <strong>Anschrift:</strong>

                      {{ documentValues["ship_street"] }}
                      {{ documentValues["ship_street_number"] }}
                      {{ documentValues["ship_city"] }}

                      <span
                        v-if="
                          !documentValues.ship_street &&
                          !documentValues.ship_city
                        "
                      >
                        –
                      </span>
                    </h4>
                  </div>

                  <div class="py-1">
                    <h4 title="ship_country">
                      <i class="i-Flag-2 mr-3" />
                      <strong>Land:</strong>

                      {{ documentValues["ship_country"] || "–" }}
                    </h4>
                  </div>

                  <div class="py-1">
                    <h4 title="ship_company">
                      <i class="i-Office-Lamp mr-3" />
                      <strong>Firma:</strong>

                      {{ documentValues["ship_company"] || "–" }}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-xl-6 mb-4">
            <div
              class="card card-profile-1 shadow p-3 mb-5 bg-white"
              style="height: 100%"
            >
              <div class="card-body">
                <div class="card-title">
                  <h2>Bestelldetails</h2>
                </div>

                <div class="text-left" style="margin: auto">
                  <div class="py-1">
                    <h4 title="amount">
                      <i class="i-Bag-Items mr-3" />
                      <strong>Anzahl:</strong>

                      {{ documentValues["amount"] || "?" }}x
                    </h4>
                  </div>

                  <div
                    v-if="documentValues && documentValues.downloadable_code"
                    class="py-1"
                  >
                    <h4 title="orderid">
                      <i class="i-Fingerprint mr-3" />
                      <strong>Hörbuch-Code: </strong>

                      <code>{{ documentValues.downloadable_code }}</code>
                    </h4>
                  </div>

                  <div class="py-1">
                    <h4 title="orderid">
                      <i class="i-Fingerprint mr-3" />
                      <strong>BestellNr.: </strong>

                      <code>{{ documentValues.orderid }}</code>
                    </h4>
                  </div>

                  <div class="py-1">
                    <h4 title="tracking_no">
                      <i class="i-Gift-Box mr-3" />
                      <strong>TrackingNr: </strong>

                      <code>
                        <a
                          target="_blank"
                          v-bind:href="
                            'https://nolp.dhl.de/nextt-online-public/report_popup.jsp?idc=' +
                            documentValues['tracking_no']
                          "
                          >{{ documentValues["tracking_no"] || "–" }}</a
                        ></code
                      >
                    </h4>
                  </div>

                  <div class="py-1">
                    <h4 title="order_date">
                      <i class="i-Clock mr-3" />
                      <strong>Datum:</strong>

                      {{ orderDisplayDate || "–" }}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 mb-4">
            <div
              class="card card-profile-1 shadow p-3 mb-5 bg-white"
              style="height: 100%"
            >
              <div class="card-body">
                <div class="card-title">
                  <h2>Zahlungsdetails</h2>
                </div>

                <div class="text-left" style="margin: auto">
                  <div class="py-1">
                    <h4 title="payment_email">
                      <i class="i-Mail mr-3" />
                      <strong>E-Mail: </strong>

                      <a :href="`mailto:${documentValues['payment_email']}`">
                        {{ documentValues["payment_email"] || "–" }}
                      </a>
                    </h4>
                  </div>

                  <div class="py-1">
                    <h4 title="payment_status">
                      <i class="i-Checkout mr-3" />
                      <strong>Status:</strong>

                      <code
                        v-if="documentValues['payment_status'] === 'VERIFIED'"
                        style="color: green"
                      >
                        {{ documentValues["payment_status"] }}
                      </code>

                      <code
                        v-else-if="
                          documentValues['payment_status'] === 'CONFLICT'
                        "
                        style="color: red"
                      >
                        {{ documentValues["payment_status"] }}
                      </code>

                      <code v-else>
                        {{ documentValues["payment_status"] || "–" }}
                      </code>
                    </h4>
                  </div>

                  <div class="py-1">
                    <h4 title="payment_id">
                      <i class="i-Receipt-3 mr-3" />
                      <strong>PaymentID: </strong>

                      <code>{{ documentValues["payment_id"] || "–" }}</code>
                    </h4>
                  </div>

                  <div class="py-1">
                    <h4 title="transaction_id">
                      <i class="i-Cash-Register mr-3" />
                      <strong>TransactionID: </strong>

                      <code>{{ documentValues["transaction_id"] || "–" }}</code>
                    </h4>
                  </div>

                  <div class="py-1">
                    <h4 title="payment_date">
                      <i class="i-Clock mr-3" />
                      <strong>Datum:</strong>

                      {{ shipmentDisplayDate || "–" }}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="canEditData">
        <div class="breadcrumb">
          <h1>Bestellung bearbeiten</h1>
        </div>
        <div class="separator-breadcrumb border-top"></div>

        <div class="container">
          <div class="mx-auto mb-4">
            <div class="card card-profile-1 shadow mb-5 bg-white">
              <div class="card-body">
                <div class="row">
                  <div
                    v-if="documentValues.customer_verified"
                    class="col-md-12"
                  >
                    <h3 class="font-weight-bold pt-3">
                      Die Bestellung wurde erfolgreich bearbeitet und bestätigt.
                      Es geht ein neues Paket an diese Adresse raus. Sie kann
                      deshalb nicht mehr bearbeitet werden.
                    </h3>
                  </div>

                  <div v-else class="col-md-12 p-4">
                    <form name="CustomTable">
                      <div class="row">
                        <div class="col-md-12">
                          <h3>Rechnungs + Lieferadresse</h3>
                          <div class="row">
                            <div
                              v-for="(
                                fieldInfo, fieldName
                              ) in documentEditFields"
                              :key="fieldName"
                              class="col-md-6 form-group mb-3"
                            >
                              <label>{{ fieldInfo.name }}</label>
                              <input
                                v-if="fieldInfo.type === 'String'"
                                v-model="documentValues[fieldName]"
                                class="form-control form-control-rounded"
                                type="text"
                                v-bind:placeholder="fieldInfo.name"
                              />
                              <input
                                v-if="fieldInfo.type === 'Number'"
                                v-model="documentValues[fieldName]"
                                class="form-control form-control-rounded"
                                type="number"
                                v-bind:placeholder="fieldInfo.name"
                              />
                            </div>
                          </div>
                        </div>

                        <div
                          v-if="documentValues.ship_country"
                          class="col-md-12 pt-4"
                        >
                          <h3>Optionale alternative Lieferadresse</h3>
                          <p>
                            Wenn die Lieferadresse leer bleibt, wird deine
                            Bestellung an die obige Rechnungsadresse versendet.
                          </p>
                          <div class="row">
                            <div
                              v-for="(
                                fieldInfo, fieldName
                              ) in documentEditFieldsRA"
                              :key="fieldName"
                              class="col-md-6 form-group mb-3"
                            >
                              <label>{{ fieldInfo.name }}</label>
                              <input
                                v-if="fieldInfo.type === 'String'"
                                v-model="documentValues[fieldName]"
                                class="form-control form-control-rounded"
                                type="text"
                                v-bind:placeholder="fieldInfo.name"
                              />
                              <input
                                v-if="fieldInfo.type === 'Number'"
                                v-model="documentValues[fieldName]"
                                class="form-control form-control-rounded"
                                type="number"
                                v-bind:placeholder="fieldInfo.name"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12 pt-3">
                          <button
                            :disabled="!saveButtonsActive"
                            class="btn btn-primary"
                            type="button"
                            @click="updateAddress"
                          >
                            {{
                              saveButtonsActive
                                ? "Daten speichern"
                                : "Speichern..."
                            }}
                          </button>
                        </div>

                        <div class="col-md-12 pt-3 px-4">
                          Stelle bitte sicher, dass deine Adresse korrekt ist.
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!documentValues.customer_verified && mustConfirmData">
        <div class="breadcrumb">
          <h1>Bestellung bestätigen</h1>
        </div>
        <div class="separator-breadcrumb border-top"></div>

        <div class="container">
          <div class="mx-auto mb-4">
            <div class="card card-profile-1 shadow mb-5 bg-white">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <h3 class="font-weight-bold pt-3">
                      Diese Daten bestätigen
                    </h3>
                    <h4>
                      Wenn du die oberen Daten bestätigst, senden wir ein neues
                      Paket an diese Adresse raus.<br />Bitte
                      <strong>stelle sicher</strong>, dass die eingetragenen
                      Daten <strong>korrekt</strong> sind!<br />Beachte dabei
                      Leerzeichen und Rechtschreibung der Straßennamen, usw.
                    </h4>

                    <button
                      :disabled="!saveButtonsActive"
                      class="btn btn-success"
                      type="button"
                      @click="markOrderAddressAsVerified"
                    >
                      {{
                        saveButtonsActive
                          ? "Bestätigen & Paket abschicken"
                          : "Lädt..."
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="canCancelOrder" class="container">
        <div class="mx-auto mb-4">
          <div class="card card-profile-1 shadow mb-5 bg-white">
            <div class="card-body">
              <div class="input-group mx-auto mb-4">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    Warum willst du deine<br />
                    Bestellung stornieren?
                  </span>
                </div>
                <textarea
                  v-model="cancellationReason"
                  class="form-control"
                  rows="3"
                ></textarea>
              </div>

              <div aria-label="Third group" class="btn-group-lg" role="group">
                <button
                  :disabled="!cancelButtonActive"
                  class="btn btn-danger"
                  type="button"
                  @click="cancelOrder"
                >
                  {{
                    cancelButtonActive
                      ? "Stornierungsanfrage senden"
                      : "Lädt..."
                  }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ============ Body content End ============= -->
  </div>
  <!--=============== End app-admin-wrap ================-->
</template>

<script>
/* eslint-disable no-console */

import axios from "axios";
import Swal from "sweetalert2";
import { collection, getDoc, doc, updateDoc } from "firebase/firestore";
import { auth, firestore } from "../config/db";

export default {
  name: "ManageOrder",

  data() {
    return {
      email: "",
      collectionId: "XOdPbTGIbK4DE4xhXZfZ",
      order: this.$router.currentRoute.value.params.order, // Order
      collection: this.$router.currentRoute.value.params.collection, // Firebase collection
      documentValues: {}, // Value

      apiBase: "", // General API base
      invoiceApiBase: "https://invoice-service.jp-superstore.de", // Invoice API base
      invoiceUrl: null, // Path to download invoices from, if possible
      paceTicketsApiBase: "https://pace-ticket-service.jp-superstore.de",
      paceTicketsUrl: null,

      saveButtonsActive: false,
      orderInWordpress: undefined,
      canEditData: false,
      mustConfirmData: false,
      canCancelOrder: false,
      cancelButtonActive: true,

      orderDisplayDate: "",
      shipmentDisplayDate: "",
      cancellationReason: "",

      // Fields the customer is allowed to edit (customer fields)
      documentEditFields: {
        company: { name: "Firma", type: "String" },
        firstname: { name: "Vorname", type: "String" },
        lastname: { name: "Nachname", type: "String" },
        street: {
          name: "Straße",
          type: "String",
        },
        street_number: {
          name: "HausNr.",
          type: "String",
        },
        plz: { name: "PLZ", type: "String" },
        city: { name: "Stadt", type: "String" },
      },

      // Fields the customer is allowed to edit (delivery fields)
      documentEditFieldsRA: {
        ship_company: { name: "Firma", type: "String" },
        ship_firstname: { name: "Vorname", type: "String" },
        ship_lastname: { name: "Nachname", type: "String" },
        ship_street: {
          name: "Lieferstraße",
          type: "String",
        },
        ship_street_number: {
          name: "HausNr.",
          type: "String",
        },
        ship_plz: { name: "PLZ", type: "String" },
        ship_city: { name: "Stadt", type: "String" },
      },
    };
  },

  created: function () {
    const apiBaseMapping = {
      "2020_3-carlender-orders":
        "https://carlender2020.jp-superstore.de/wp-json",
      "2020_2-biografie-orders": "https://book2020.jp-superstore.de/wp-json",
      "2021_1-audiobook-orders":
        "https://2021_1-audiobook.jp-superstore.de/wp-json",
      "pace-ticket-orders": "https://pace-ticket-service.jp-superstore.de",
      "2021_2-carlender-orders":
        "https://carlender2022.jp-superstore.de/wp-json",
      "2021_3-biografie-orders": "https://book2020.jp-superstore.de/wp-json",
      "2022_1-boostwars-orders":
        "https://boostwars2022.jp-superstore.de/wp-json",
      "2022_2-carlender-orders":
        "https://carlender2023.jp-superstore.de/wp-json",
      "2023_4-carlender-orders":
        "https://carlender2024.jp-superstore.de/wp-json",
    };
    this.apiBase = apiBaseMapping[this.collection];
    this.userData();
  },

  methods: {
    /**
     * Log out current user
     */
    logout: function () {
      auth.signOut().then(() => {
        console.log("Signed out");
        this.$router.replace({ path: "/login" });
      });
    },

    /**
     * Get user data
     */
    userData: function () {
      const user = auth.currentUser;

      if (user != null) {
        this.email = user.email;
        this.getOrder();
      }
    },

    /**
     * Get current order
     */
    getOrder: function () {
      getDoc(doc(firestore, this.collection, this.order))
        .then((doc) => {
          if (doc.exists()) {
            this.documentValues = doc.data();

            this.shipmentDisplayDate = new Date(
              this.documentValues["payment_date"],
            ).toLocaleString("de");

            this.orderDisplayDate = new Date(
              this.documentValues["order_date"],
            ).toLocaleString("de");

            this.saveButtonsActive = true;

            // ---- Special cases ----

            // Special case for PACE Tickets
            if (this.collection === "pace-ticket-orders") {
              const keys = [
                "amount_adult",
                "amount_adult_tablet",
                "amount_reduced",
                "amount_reduced_tablet",
              ];

              this.documentValues.amount = keys
                .map((key) => parseInt(this.documentValues[key]))
                .filter((amount) => !isNaN(amount))
                .reduce((a, b) => a + b);
            }

            this.getWordPressOrderByOrderId(this.documentValues.orderid);

            // Special case for Carlender 2023
            if (
              this.collection === "2022_2-carlender-orders" &&
              this.documentValues.payment_status === "VERIFIED"
            ) {
              this.canEditData = true;
            }
          } else {
            console.error("Document not found:", this.order);
          }
        })
        .catch((err) => {
          console.error("Error getting document", err);
        });
    },

    getCanEditByStatus: function (status) {
      const status_list = [
        "invalid-address",
        "came-back",
        "is-imported",
        "processed",
        "dhl-generated",
      ];

      return status_list.includes(status);
    },

    getCanCancelByStatus: function (status) {
      const status_list = [
        "invalid-address",
        "came-back",
        "is-imported",
        "processed",
      ];

      return status_list.includes(status);
    },

    mustConfirmByStatus: function (status) {
      const status_list = ["invalid-address"];

      return status_list.includes(status);
    },

    digestToken: async function (str) {
      const msgUint8 = new TextEncoder().encode(str.toLowerCase());
      const hashBuffer = await crypto.subtle.digest("SHA-1", msgUint8);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
    },

    /**
     * Get order from WordPress
     */
    getWordPressOrderByOrderId: function (orderId) {
      this.digestToken(this.documentValues.email).then((mtoken) => {
        const wcUrl = `${this.apiBase}/jp/order_info/${orderId}?mtoken=${mtoken}`;

        axios
          .get(wcUrl)
          .then(({ data }) => {
            this.orderInWordpress = data;

            if (!this.documentValues.wc_id) {
              this.documentValues.wc_id = data.wc_id;

              this.updateInfo().then(() => (this.saveButtonsActive = true));
            }

            this.mustConfirmData = this.mustConfirmByStatus(data.status);
            this.canEditData = this.getCanEditByStatus(data.status);
            this.canCancelOrder =
              this.getCanCancelByStatus(data.status) &&
              this.documentValues.payment_status === "VERIFIED";
          })
          .catch((err) => {
            this.orderInWordpress = null;

            this.mustConfirmData = false;
            this.canEditData = false;
            this.canCancelOrder = false;
          });

        const invoiceJsonUrl = `${this.invoiceApiBase}/orders/${this.documentValues.orderid}/invoice?mtoken=${mtoken}`;
        const invoicePdfUrl = `${this.invoiceApiBase}/orders/${this.documentValues.orderid}/invoice/download?mtoken=${mtoken}`;

        axios
          .get(invoiceJsonUrl)
          .then(() => (this.invoiceUrl = invoicePdfUrl))
          .catch((err) => console.error("Invoice could not be found:", err));

        if (this.collection === "pace-ticket-orders") {
          this.paceTicketsUrl = `${this.paceTicketsApiBase}/orders/${this.documentValues.orderid}/tickets?mtoken=${mtoken}`;
        }
      });
    },

    /**
     * Update info in Firebase
     */
    updateInfo: function () {
      this.saveButtonsActive = false;

      return updateDoc(
        doc(firestore, this.collection, this.order),
        this.documentValues,
      ).then(() => this.getOrder());

      // `getOrder()` sets the save buttons back to active (true)
    },

    updateAddress: function () {
      this.updateInfo().then(() => {
        Swal.fire({
          icon: "success",
          title: "Neue Adresse gespeichert!",
          text: "Deine eingegebenen Adressdaten wurden erfolgreich gespeichert.",
          showConfirmButton: true,
        });
      });
    },

    /**
     * Mark an order address as verified and update info in Firebase
     */
    markOrderAddressAsVerified: function () {
      this.documentValues.customer_verified = true;

      return this.updateInfo();
    },

    cancelOrder: function () {
      const message = "Willst du deine Bestellung wirklich stornieren?";

      if (confirm(message)) {
        this.cancelButtonActive = false;

        this.documentValues.payment_status = "REFUND-REQUESTED";
        this.documentValues.cancellation_reason = this.cancellationReason;

        this.updateInfo()
          .then(() => (this.cancellationReason = ""))
          .finally(() => {
            this.cancelButtonActive = false;
          });
      }
    },
  },
};
</script>
