import App from "./App.vue";
import router from "./router";
import { auth, firebaseApp } from "./config/db";
import { createApp } from "vue";
import { VueFireAuth, VueFire } from "vuefire";

let app = "";
auth.onAuthStateChanged(() => {
  if (!app) {
    /* eslint-disable no-new */
    app = createApp(App);
    app.use(VueFire, {
      firebaseApp,
      modules: [VueFireAuth()],
    });
    app.use(router);
    router.isReady().then(() => app.mount("#app"));
  }
});
